<template>
  <Card>
    <template #title>
      {{ $t("video.finished.title") }}
    </template>
    <template #subtitle>
      {{ $t("video.finished.description") }}
    </template>
    <template #content>
      <video-card-body :process="process" />
    </template>
    <template #footer>
      <div class="p-grid p-nogutter p-justify-between">
        <Button
          data-testid="button-viewer"
          :label="translation.viewer"
          icon="pi pi-cloud-download"
          class="p-mr-2"
          @click="onDownloadViewer"
        />
        <div>
          <Button
            data-testid="button-binary"
            :label="translation.annotations"
            icon="pi pi-cloud-download"
            class="p-mr-2"
            @click="onDownloadBinary"
          />
          <Button
            data-testid="button-video"
            :label="translation.video"
            icon="pi pi-cloud-download"
            @click="onDownloadVideo"
          />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Button from "primevue/button";
import Card from "primevue/card";
import Tooltip from "primevue/tooltip";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import {
  DOWNLOAD_BINARY,
  DOWNLOAD_VIDEO,
  DOWNLOAD_VIEWER,
} from "../../store/modules/process/process.actions.type";
import VideoCardBody from "./VideoCardBody";

export default {
  name: "StepVideoFinishedSuccessfully",
  components: {
    Button,
    Card,
    VideoCardBody,
  },
  directives: {
    tooltip: Tooltip,
  },
  props: {
    process: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const translation = {
      annotations: t("video.finished.button_download_annotations"),
      video: t("video.finished.button_download_video"),
      viewer: t("video.finished.button_download_viewer"),
      downloadLink: t("link.bioobserver.viewer"),
    };

    const onDownloadBinary = async () =>
      await store.dispatch(DOWNLOAD_BINARY, props.process.id);

    const onDownloadVideo = async () =>
      await store.dispatch(DOWNLOAD_VIDEO, props.process.id);

    const onDownloadViewer = async () =>
      await store.dispatch(DOWNLOAD_VIEWER, translation.downloadLink);

    return {
      onDownloadBinary,
      onDownloadVideo,
      onDownloadViewer,
      translation,
    };
  },
};
</script>
