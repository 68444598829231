<template>
  <Card>
    <template #title>
      {{ $t("video.rejected.title") }}
    </template>
    <template #subtitle>
      <p>{{ $t("video.rejected.description") }}</p>
    </template>
    <template #content>
      <p>{{ $t("video.rejected.content_text") }}</p>
    </template>
  </Card>
</template>

<script>
import Card from "primevue/card";

export default {
  name: "StepVideoRejected",
  components: {
    Card,
  },
};
</script>
