<template>
  <div v-if="process?.status === PROCESS_STATUS.FINISHED">
    <step-video-finished-successfully :process="process" />
  </div>
  <div v-else-if="process?.status === PROCESS_STATUS.FAILED">
    <step-video-finished-failed />
  </div>
  <div v-else-if="process?.status === PROCESS_STATUS.REJECTED">
    <step-video-finished-rejected />
  </div>
  <div v-else>
    <step-video-finished-processing />
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import { PROCESS_STATUS } from "../../models/ProcessStatus";
import { FETCHING_PROCESSES } from "../../store/modules/process/process.actions.type";
import {
  COUNT_PROCESSES,
  GET_PROCESS_BY_ID,
} from "../../store/modules/process/process.getters.type";
import StepVideoFinishedFailed from "./StepVideoFinishedFailed";
import StepVideoFinishedProcessing from "./StepVideoFinishedProcessing";
import StepVideoFinishedRejected from "./StepVideoFinishedRejected";
import StepVideoFinishedSuccessfully from "./StepVideoFinishedSuccessfully";

export default {
  components: {
    StepVideoFinishedRejected,
    StepVideoFinishedProcessing,
    StepVideoFinishedFailed,
    StepVideoFinishedSuccessfully,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const route = useRoute();
    const { processId } = route.params;
    const process = computed(() => store.getters[GET_PROCESS_BY_ID](processId));

    onMounted(async () => {
      if (store.getters[COUNT_PROCESSES] === null) {
        await store.dispatch(FETCHING_PROCESSES);
      }

      if (process.value === undefined) {
        await router.push({ name: "upload-video" });
      }
    });

    return {
      process,
      PROCESS_STATUS,
    };
  },
};
</script>
