<template>
  <div v-if="process" class="p-grid p-fluid dashboard">
    <div class="p-col-12 p-lg-6">
      <h5>{{ $t("video.common.name") }}</h5>
      <p>{{ process.name }}</p>
    </div>
    <div class="p-col-12 p-lg-6">
      <h5>{{ $t("video.common.keywords") }}</h5>
      <p>{{ process.getKeywordsAsString() }}</p>
    </div>
    <div class="p-colr-12 p-lg-12">
      <h5>{{ $t("video.common.description") }}</h5>
      <p>{{ process.description }}</p>
    </div>
    <div class="p-col-12 p-lg-12">
      <h5>{{ $t("video.common.video_details") }}</h5>
      <div class="p-grid">
        <div class="p-col-6 p-lg-6">
          <ul>
            <li>
              <strong>{{ $t("video.common.video_duration") }}</strong>
              {{
                $t("video.common.video_duration_value", {
                  time: process.video.durationInMinutes(),
                })
              }}
            </li>
            <li>
              <strong>{{ $t("video.common.video_resolution") }}</strong>
              {{ process.video.resolution }}
            </li>
            <li>
              <strong>{{ $t("video.common.video_size") }}</strong>
              {{ process.video.humanReadableSize() }}
            </li>
            <li>
              <strong>{{ $t("video.common.codec") }}</strong>
              {{ process.video.format }}
            </li>
          </ul>
        </div>
        <div class="p-col-6 p-lg-6">
          <figure class="screenshot-figure p-m-0">
            <img
              :src="process.video.getFrame()"
              :alt="framePlaceholder"
              role="img"
            />
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "VideoCardBody",
  props: {
    process: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { t } = useI18n();
    const framePlaceholder = t("video.common.video_frame_placeholder");
    return {
      framePlaceholder,
    };
  },
};
</script>

<style scoped>
.screenshot-figure img {
  max-width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
</style>
