<template>
  <Card>
    <template #title>
      {{ $t("video.failed.title") }}
    </template>
    <template #subtitle>
      <p>{{ $t("video.failed.description") }}</p>
    </template>
    <template #content>
      <p>{{ $t("video.failed.content_text1") }}</p>
      <p>{{ $t("video.failed.content_text2") }}</p>
    </template>
  </Card>
</template>

<script>
import Card from "primevue/card";

export default {
  name: "StepVideoFailed",
  components: {
    Card,
  },
};
</script>
